import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import IconButtom from './IconButton';
import useWindowDimensions from '../lib/useWindowDimensions';

const Wrapper = styled(Flex)`
  background-color: rgba(102, 102, 102, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  transition: 0.5s;
  opacity: 1;
  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
  }
`;

const Container = styled(Flex)`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width || '60%'};
  background-color: #ffffff;
  border-radius: 8px;
  transition: 0.5s;
  opacity: 1;
  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
  }
`;

const Close = ({ onClick }) => (
  <div
    onClick={onClick}
    css={`
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    `}
  >
    <FontAwesomeIcon icon={faTimes} style={{ fontSize: 20 }} color="#666666" />
  </div>
);

const Header = styled(Flex)`
  position: relative;
  margin: 10px;
`;

const Body = styled.div`
  overflow-y: scroll;
  margin: 20px;
`;

const Footer = styled(Flex)`
  margin: 10px;
`;

const DefaultFooterContent = ({ onClose }) => (
  <IconButtom size="sm" label="閉じる" icon={faTimes} buttonColor="#3e3e3e" onClick={onClose} />
);

const Modal = ({ open, onClose, header, body, footer, width }) => {
  const { height } = useWindowDimensions();
  const modalHeight = height * 0.9;
  return (
    <Wrapper center className={open ? '' : 'hide'}>
      <Container column height={modalHeight} width={width} className={open ? '' : 'hide'}>
        <Header center>
          <Close onClick={onClose} />
          {header}
        </Header>
        <Body center>{body}</Body>
        <Footer center>{footer || <DefaultFooterContent onClose={onClose} />}</Footer>
      </Container>
    </Wrapper>
  );
};

export default Modal;
