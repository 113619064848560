import React, { useState } from 'react';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Card from '../components/Card';
import Modal from '../components/Modal';
import IconButtom from '../components/IconButton';

import va1lg from '../assets/img/va-1-lg.png';

const Bold = styled.span`
  font-weight: bold;
`;
const RedBold = styled(Bold)`
  font-weight: bold;
  text-decoration: underline;
  color: #e83d00;
`;
const Quote = styled(P)`
  font-size: 12px;
`;

const menus = [
  {
    title: 'インフルエンザワクチン',
    price: '¥4,400(税込)',
    content: () => (
      <div>
        <P
          css={`
            text-align: center;
          `}
        >
          ※妊娠中の方でも接種可能です。
        </P>
      </div>
    ),
    omitted: false,
  },
  { title: 'MR(麻疹・風疹)ワクチン', price: '¥10,000(税込)', omitted: false },
  {
    title: '風疹ワクチン',
    price: '取り扱い中止',
    content: () => (
      <div>
        <P>
          風疹は、妊娠初期～中期の妊婦さんが罹患すると赤ちゃんに白内障、先天性心疾患、難聴、精神発達遅滞を起こしてしまうことがあり（先天性風疹症候群といいます）、妊娠前にしっかり抗体を持っておくことが必要です。
        </P>
        <P>
          妊娠を考えている方、心配な方は、早めに風疹の抗体検査を受け、抗体がなければ予防接種を受けていただくことをお勧めいたします。
        </P>
        <P>パートナーの方も同様に、抗体のチェックと予防接種をお勧めいたします。</P>
        <P>
          なお、理論上の危険を避けるため、予防接種後は２ヶ月間避妊が必要です。（MRワクチンも同様）
        </P>
        <P>
          <Bold>風疹抗体検査(HI法)：1,500円(税込)</Bold>
          <br />
          <Bold>風疹ワクチン：5,000円(税込)</Bold>
        </P>
        <P>大人の方が風疹ワクチンを受けられる場合、接種回数は１～２回です。</P>
      </div>
    ),
    omitted: false,
  },
  {
    title: '麻疹ワクチン',
    price: '取り扱い中止',
    content: () => (
      <div>
        <P>
          麻疹はときどき流行してニュースになりますが、かなり感染力が強く、脳炎などを発症してしまうと命にかかわる、大変危険な病気です。
          <br />
          日本はきちんとワクチンを打っていない人も多く、「麻疹の輸出国」といわれているようです。
          <br />
          しかも発症したときに決定的な治療法がありません。
        </P>
        <P>
          <Bold>麻疹抗体検査（IgG）：要確認（税込）</Bold>
          <br />
          <Bold>麻疹ワクチン：5,000円（税込）</Bold>
        </P>
        <P>大人の方が麻疹ワクチンを受けられる場合、接種回数は１～２回です。</P>
      </div>
    ),
    omitted: false,
  },
  {
    title: `子宮頸がんワクチン
(サーバリックス、ガーダシル)`,
    price: '¥16,800(税込)/回',
    content: () => (
      <div>
        <P>
          <Bold>推奨年齢</Bold>
          <br />
          <RedBold>小学6年生～高校1年生相当の女子。</RedBold>
          <br />
          （推奨年齢以上の女性でもワクチンの接種は有効です。ただし、全ての子宮頸がんを予防できるわけではないので、子宮頸がん検診は継続して受けるようにしてください。）
        </P>
        <P>
          <Bold>接種間隔</Bold>
          <br />
          <RedBold>サーバリックス（2価ワクチン）</RedBold>
          <br />
          初回接種の1か月後に2回目
          <br />
          初回接種の6か月後に3回目
        </P>
        <P>
          <RedBold>ガーダシル（4価ワクチン）</RedBold>
          <br />
          初回接種の2か月後に2回目
          <br />
          初回接種の6か月後に3回目
          <br />
          ２回目、３回目は、初回に接種したワクチンと同じ種類のワクチンを受ける必要があります。
        </P>
        <P>
          子宮頸がんワクチン（HPVワクチン）について、「危険なワクチンだ」という印象を持たれている方も多いかと思います。
          <br />
          しかし、実は「HPVワクチンと「多様な症状」の間に関連性を認めない」という信頼性の高い研究結果が出ています。
        </P>
        <Quote>
          No association between HPV vaccine and reported post-vaccination symptoms in Japanese
          young women: Results of the Nagoya study. Papillomavirus Res. 2018 Jun;5:96-103.
        </Quote>
        <P>
          WHO（世界保健機関）も、全世界に向けて出したHPVワクチンの安全性を伝える声明文の中で、日本を名指しで非難する異常事態となっています。
        </P>
        <Quote>
          Global Advisory Committee on Vaccine safety Statement on Safety of HPV vaccines 17
          <br />
          December2015
        </Quote>
        <P>
          あれだけ不安を煽るような報道がされながら、慢性疼痛や運動障害等の接種後の諸症状とHPVワクチンとの間には因果関係は証明されていないこと、WHOが日本政府を名指しで非難していることを報道するはほとんどないのが現状です。
        </P>
        <P>
          毎年、約1万人が新たに子宮頸がんになり、約3000人が亡くなっています。
          <br />
          当院は、HPVワクチンを推奨することで、命を守りたいと考えています。
        </P>
        <P>
          参考ページ
          <br />
          HPVワクチン（子宮頸がん予防ワクチン）接種の早期の勧奨再開を強く求める声明｜日本産科婦人科学会
          <br />
          HPVワクチン（井箟一彦先生）の連載記事｜MedicalNote 「救えるはずの患者を救えない」
          <br />
          子宮頸がんワクチン副作用「問題」はなぜ起きた？｜BuzzFeedNews
        </P>
      </div>
    ),
    omitted: false,
  },
  {
    title: '水痘(水ぼうそう)・帯状疱疹ワクチン',
    price: '¥8,700(税込)',
    content: () => (
      <div>
        <P>
          2016年3月より,
          水痘ワクチンが高齢者（50歳以上）の帯状疱疹予防目的で使用できるようになりました。
          <br />
          帯状疱疹は、罹患すると強い神経痛が後遺症として残ることがあり、大変つらい病気です。
          <br />
          ワクチンでしっかり予防することをお勧めいたします。
        </P>
        <P>
          <Bold>水痘抗体検査（IgG）：要確認（税込）</Bold>
          <br />
          <Bold>水痘（水ぼうそう）・帯状疱疹ワクチン：8,500円（税込）</Bold>
        </P>
        <P>大人の方が帯状疱疹ワクチンを受けられる場合、接種回数は１回です。</P>
      </div>
    ),
    omitted: false,
  },
  {
    title: 'B型肝炎ワクチン',
    price: '¥4,600(税込)',
    content: () => (
      <div>
        <P>
          接種前にHBs抗原とHBs抗体の血液検査を行い、感染の有無・抗体が両方とも陰性の場合のみワクチンを接種します。
          <br />
          B型肝炎ワクチンの接種回数は３回行います。
          <br />
          ２回目は１か月後。
          <br />
          ３回目は初回接種から20～24週後。
          <br />
          抗体ができたかどうかのチェックは３回目の接種から１か月後に行います。
        </P>
        <P>
          <Bold>B型肝炎ワクチン：4,500円／回（３回で13,500円。検査費用は含まれません）</Bold>
          <br />
          <Bold>接種前血液検査：2,500円 接種後血液検査（３回目の接種から１か月後）：2,000円</Bold>
        </P>
      </div>
    ),
    omitted: false,
  },
  { title: 'A型肝炎ワクチン', price: '¥8,200(税込)', omitted: false },
  { title: '流行性耳下腺炎(おたふく)ワクチン', price: '¥5,100(税込)', omitted: false },
  {
    title: '破傷風トキソイド',
    price: '¥2,000(税込)/回',
    content: () => (
      <div>
        <P>
          罹患してしまうと、かなり強烈な症状が出てしまい、亡くなることも多い病気です。
          <br />
          屋外でのちょっとしたケガや、ガーデニングでのほんの小さなケガ、犬や猫につけられた傷、被災地域での活動などで破傷風に罹患してしまうことがあります。
          <br />
          抗体検査はせず、予防接種を施行します。
        </P>
        <P>
          <Bold>破傷風トキソイド（ワクチン）：2,000円（税込）</Bold>
          <br />
          <Bold>接種回数：３回 ２回目：初回接種の3～8週間後</Bold>
          <br />
          <Bold>３回目：初回接種の6～18か月後</Bold>
        </P>
      </div>
    ),
    omitted: false,
  },
  { title: '日本脳炎ワクチン', price: '¥5,700(税込)', omitted: false },
  { title: '肺炎球菌ワクチン', price: '¥7,700(税込)', omitted: false },
  { title: '小児用肺炎球菌ワクチン', price: '¥13,000(税込)', omitted: false },
  {
    title: `三種混合(DTP)ワクチン
(破傷風・百日咳・ジフテリア)`,
    price: '¥3,600(税込)',
    omitted: false,
  },
  {
    title: `四種混合(DPT-IPV)ワクチン
(破傷風・百日咳・ジフテリア・不活化ポリオ)
`,
    price: '¥10,000(税込)',
    omitted: false,
  },
  { title: 'DT(破傷風・ジフテリア)ワクチン', price: '¥3,600(税込)', omitted: false },
];

const HeadTitle = styled.span`
  font-size: 16px;
  color: #f0862e;
  font-weight: bold;
`;

const Menu = ({ title, price, content, omitted, size }) => {
  const [open, setOpen] = useState(false);
  const Head = () => (
    <div
      css={`
        width: 90%;
      `}
    >
      <Flex column center>
        {title.split('\n').map(line => (
          <HeadTitle key={line}>{line}</HeadTitle>
        ))}
        <Bold
          css={`
            margin: 10px 0;
          `}
        >
          {price}
        </Bold>
      </Flex>
      {content && <hr />}
    </div>
  );
  const Body = ({ hide }) => {
    if (!content) return null;
    return (
      <div
        css={
          (hide &&
            `
            overflow: hidden;
            height: 110px;
            width: 90%;
          `) ||
          `
            width: 90%;
          `
        }
      >
        {content()}
      </div>
    );
  };
  return (
    <>
      <Flex
        center
        css={`
          position: relative;
          margin-bottom: 40px;
        `}
      >
        <Card width="100%">
          <Head />
          <Body hide={omitted} />
        </Card>
        {omitted && (
          <Flex
            center
            css={`
              position: absolute;
              bottom: -20px;
              width: 100%;
              z-index: 1;
            `}
          >
            <IconButtom
              size="sm"
              width={160}
              height={40}
              label="続きを読む"
              icon={faFileAlt}
              buttonColor="#f0862e"
              onClick={() => setOpen(true)}
            />
          </Flex>
        )}
      </Flex>
      {omitted && (
        <Modal
          header={<Head />}
          body={<Body hide={false} />}
          open={open}
          onClose={() => setOpen(false)}
          width={(size === 'sm' && '96%') || null}
        />
      )}
    </>
  );
};

const Vaccine = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="予防接種">
      <Img src={va1lg} />
      <P>
        ワクチンは適宜発注する必要がありますので、事前にご予約いただきますよう、お願いいたします。
        <br />※
        破傷風トキソイド（ワクチン）のみ、常時在庫しておりますが、少数のため、まれに在庫がなくなる可能性があります。
      </P>
      {menus.map(({ title, price, content, omitted }) => (
        <Menu
          key={title}
          title={title}
          price={price}
          content={content}
          omitted={omitted}
          size={size}
        />
      ))}
    </SubLayout>
  );
};

export default Vaccine;
